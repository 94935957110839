const ut = require('../../src/Radar/utils');
const ProductColors = require('./ProductColors');

const PlotRadarToMap = require('./PlotRadarToMap');
const MaxRanges = require('./MaxRanges');
const calculateLngLat = require('./CalculateLngLat');

const dataNames = {
    'REF': 'reflect',
    'VEL': 'velocity',
    'SW ': 'spectrum',	// intentional space to fill 3-character requirement
    'ZDR': 'zdr',
    'PHI': 'phi',
    'RHO': 'rho',
};

function calculateVerticies(radarObj, level, options) {
    var start = Date.now();

    var mode = options.mode;
    var product;
    var elevation;
    if (level == 2) {
        product = options.product;
        elevation = options.elevation;
    } else if (level == 3) {
        // product = radarObj.textHeader.type;
        product = radarObj.productDescription.abbreviation;
        if (Array.isArray(product)) { product = product[0] }
    }

    /*
    * Create an array holding all of the azimuth values
    */
    var az = [];
    if (level == 2) {
        for (var i in radarObj.data[elevation]) { az.push(radarObj.data[elevation][i].record.azimuth) }
    } else if (level == 3) {
        for (var i in radarObj.radialPackets[0].radials) { az.push(radarObj.radialPackets[0].radials[i].startAngle) }
    }
    if (level == 3) { az.push(az[0]) }

    /*
    * Create an array holding all of the gate values
    */
    var prodValues = [];
    if (level == 2) {
        for (var i in radarObj.data[elevation]) { prodValues.push(radarObj.data[elevation][i].record[dataNames[product]].moment_data) }
    } else if (level == 3) {
        for (var i in radarObj.radialPackets[0].radials) { prodValues.push(radarObj.radialPackets[0].radials[i].bins) }
    }
    if (level == 3) { prodValues.push(prodValues[0]) }

    /*
    * Perform some adjustments on the azimuth values
    */
    if (level == 2) {
        var diff = ut.npDiff(az);
        var crossed;
        for (var i in diff) { if (diff[i] < -180) { crossed = parseInt(i) } }
        diff[crossed] += 360;
        var avg_spacing = ut.mean(diff);

        var rL = ut.removeLast(az);
        var rF = ut.removeFirst(az);

        az = [];
        for (var i in rL) { az.push((rL[i] + rF[i]) / 2) }
        az[crossed] += 180;
        az.unshift(az[0] - avg_spacing);
        az.push(az[az.length - 1] + avg_spacing);
    }

    /*
    * Calculate the ranges (distances from radar) for each gate
    */
    var prod_range;
    if (level == 2) {
        var prod_hdr = radarObj.data[elevation][0].record[dataNames[product]];
        var gate_count = prod_hdr.gate_count;
        var gate_size = prod_hdr.gate_size;
        var first_gate = prod_hdr.first_gate;
        // level 2 = 1832 0.25 2.125
        prod_range = [...Array(gate_count + 1).keys()];
        for (var i in prod_range) {
            prod_range[i] = (prod_range[i] - 0.5) * gate_size + first_gate;
        }
    } else if (level == 3) {
        var prod_hdr = radarObj.radialPackets[0];
        var maxRange = MaxRanges[radarObj.messageHeader.code]; // km
        prod_range = ut.npLinspace(0, maxRange, prod_hdr.numberBins + 1)
        // gate_count = prod_hdr.numberBins;
        // gate_size = prod_hdr.radials[0].angleDelta;
        // first_gate = prod_hdr.firstBin;
    }

    // xlocs = prod_range * np.sin(np.deg2rad(az[:, np.newaxis]))
    // ylocs = prod_range * np.cos(np.deg2rad(az[:, np.newaxis]))
    //var xlocs = getXlocs(prod_range, az);
    //var ylocs = getYlocs(prod_range, az);
    //console.log(`Calculated locs in ${Date.now() - start} ms`);

    var colorData = ProductColors[product];
    var values = [...colorData.values];
    values = ut.scaleValues(values, product);

    var radarLatLng = ut.getRadarLatLng(radarObj, level);

    // var chunksReturned = 0;
    // var verticiesArr = [];
    // var colorsArr = [];

    /*
    * We are using a web worker for this because the heavy calculations
    * that need to run will crash the website on a mobile browser.
    */
    // var w = work(require('./calculateLngLat.js'));
    // w.addEventListener('message', function(ev) {
    var { points, colors } = calculateLngLat({ data: [prod_range, az, prodValues, radarLatLng, colorData.colors, values, mode] });
    return { points, colors, product, radarLatLng };
    // PlotRadarToMap(points, colors, product, radarLatLng);

    // w.postMessage([prod_range, az, prodValues, radarLatLng, colorData.colors, values, mode]); // send the worker a message

    //plotRadarToMap(points, colors, product);
    // var vertexF32 = new Float32Array(points);
    // var colorF32 = new Float32Array(colors);

    // console.log(vertexF32)
    // console.log(colorF32)
}

module.exports = calculateVerticies;