const code = 61;
const abbreviation = ['NTV'];
const description = 'Tornadic Vortex Signature';
const formatter = require('./formatter');

module.exports = {
	code,
	abbreviation,
	description,
	formatter,

	productDescription: {
	},
};
