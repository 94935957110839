import axios from "axios";
import ut from "./utils";
import { createWebGLTexture } from "./Helpers";
import { vertexSource, fragmentSource } from "./WebGLSources";

import { scaleValues, loadFileObject, toBuffer } from "./utils";
import calculateVerticies from "../../lib/radar/CalculateVerticies"
import l3parse from "../../lib/nexrad-level-3-data/src"
import { loadRadarFile } from "./Helpers";
const mathjs = require('mathjs')

const ProductColors = require('../../lib/radar/ProductColors');

export default class RadarScan {

    constructor(urlBase, scanKey, scanDateTime, level = 3, map, radarCache) {
        this.urlBase = urlBase;
        this.scanKey = scanKey;
        this.scanDateTime = scanDateTime;
        this.level = level;

        this.map = map;

        this.radarData = {}

        this.radarCache = radarCache

        this.shouldRenderInitially = false;
        this.loaded = false;

        this.worker = new Worker(new URL('./RadarWorker.js', import.meta.url))

        this.worker.onmessage = (e) => {
            if (e.data.cmd == "LoadRadarFile" && e.data.id == this.scanKey) {
                this.radarData = e.data.data;
                this.radarCache.put(this.scanKey, new Response(JSON.stringify(this.radarData)))
                this.calculateVerticies();

            }
            if (e.data.cmd == "CalculateVerticies" && e.data.id == this.scanKey) {
                var data = e.data;
                this.addToMap(data.points, data.colors, data.product, data.radarLatLng)
                this.loaded = true;
                this.worker.terminate();
            }
        }
    }

    calculateVerticies() {
        this.worker.postMessage({
            cmd: 'CalculateVerticies',
            id: this.scanKey,
            data: [
                this.radarData,
                3,
                { 'mode': 'mapPlot' }
            ]
        })
    }

    async init(shouldRenderInitially = false) {

        // console.log(this.scanKey + ' Init...') // DEBUG
        if (shouldRenderInitially) {
            this.shouldRenderInitially = shouldRenderInitially;
        }
        const radarEntry = await this.radarCache.match(this.scanKey);
        if (!radarEntry) {
            this.worker.postMessage({ cmd: 'LoadRadarFile', id: this.scanKey, data: [this.urlBase, this.scanKey, this.level] })
        } else {
            var cacheJson = await radarEntry.json()
            this.radarData = cacheJson;
            this.calculateVerticies();
        }

        return new Promise(resolve => {
            var checkForLoaded = () => {
                // console.log(this.scanKey + ' Checking...') // DEBUG
                if (this.loaded == true) {
                    clearInterval(interval);
                    // console.log(this.scanKey + ' Resolving...') // DEBUG
                    resolve(true);
                }
            }
            var interval = setInterval(checkForLoaded, 100);
        })

    }

    addToMap(verticiesArr, colorsArr, product, radarLatLng) {

        var colorScaleData = ProductColors[product];
        var colors = colorScaleData.colors;
        var values = [...colorScaleData.values];
        // console.log(values)
        // console.log(product)
        values = scaleValues(values, product);
        // console.log(values)
        const cmin = values[0];
        const cmax = values[values.length - 1];
        // console.log(cmin, cmax)

        //var vertexF32 = new Float32Array(verticiesArr);
        //var colorF32 = new Float32Array(colorsArr);
        var vertexF32 = verticiesArr;
        var colorF32 = colorsArr;

        var imagedata;
        var imagetexture;

        var scanKey = this.scanKey

        var layer = {
            id: this.scanKey,
            type: 'custom',

            onAdd: function (map, gl) {
                var initTime = new Date()
                // createAndShowColorbar(colors, values);
                imagedata = createWebGLTexture(colors, values);
                imagetexture = gl.createTexture();
                gl.bindTexture(gl.TEXTURE_2D, imagetexture);

                var vertexShader = gl.createShader(gl.VERTEX_SHADER);
                gl.shaderSource(vertexShader, vertexSource);
                gl.compileShader(vertexShader);

                var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
                gl.shaderSource(fragmentShader, fragmentSource);
                gl.compileShader(fragmentShader);

                this.program = gl.createProgram();
                gl.attachShader(this.program, vertexShader);
                gl.attachShader(this.program, fragmentShader);
                gl.linkProgram(this.program);

                this.positionLocation = gl.getAttribLocation(this.program, 'aPosition');
                this.colorLocation = gl.getAttribLocation(this.program, 'aColor');
                this.textureLocation = gl.getUniformLocation(this.program, 'u_texture');
                this.minmaxLocation = gl.getUniformLocation(this.program, 'minmax');
                this.radarLngLatLocation = gl.getUniformLocation(this.program, 'radarLatLng');

                // var newVertexF32 = new Float32Array(vertexF32.length * 2);
                // var offset = 0;
                // for (var i = 0; i < vertexF32.length; i += 2) {
                //     var x = vertexF32[i];
                //     var y = vertexF32[i + 1];
                //     var f32x = x - x;
                //     var f32y = y - y;
                //     // if (f32x != 0) { console.log(x) }
                //     // if (f32y != 0) { console.log(y) }

                //     newVertexF32[offset] = x;
                //     newVertexF32[offset + 1] = y;
                //     newVertexF32[offset + 2] = f32x;
                //     newVertexF32[offset + 3] = f32y;
                //     offset += 4;
                // }

                this.vertexBuffer = gl.createBuffer();
                gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
                gl.bufferData(
                    gl.ARRAY_BUFFER,
                    vertexF32,
                    gl.STATIC_DRAW
                );

                this.colorBuffer = gl.createBuffer();
                gl.bindBuffer(gl.ARRAY_BUFFER, this.colorBuffer);
                gl.bufferData(
                    gl.ARRAY_BUFFER,
                    colorF32,
                    gl.STATIC_DRAW
                );
                var endTime = new Date()
                console.log(scanKey + " - Prerendered in: " + (endTime - initTime))
            },
            render: function (gl, matrix) {
                // var initTime = new Date()
                gl.useProgram(this.program);

                //get xyz camera coordinates and w_clip value for the camera position. (expose camera coord in mapbox so this becomes unnecessary?)
                function _get_eye(mat) {
                    mat = [[mat[0], mat[4], mat[8], mat[12]], [mat[1], mat[5], mat[9], mat[13]], [mat[2], mat[6], mat[10], mat[14]], [mat[3], mat[7], mat[11], mat[15]]];
                    var eye = mathjs.lusolve(mat, [[0], [0], [0], [1]]);
                    var clip_w = 1.0 / eye[3][0];
                    eye = mathjs.divide(eye, eye[3][0]);
                    eye[3][0] = clip_w;
                    return mathjs.flatten(eye);
                }
                var eye_high = _get_eye(matrix);
                var eye_low = eye_high.map(function (e) { return e - Math.fround(e) });
                gl.uniform4fv(gl.getUniformLocation(this.program, 'u_eye_high'), eye_high);
                gl.uniform4fv(gl.getUniformLocation(this.program, 'u_eye_low'), eye_low);

                gl.uniformMatrix4fv(
                    gl.getUniformLocation(this.program, 'u_matrix'),
                    false,
                    matrix
                );
                gl.uniform2fv(this.radarLngLatLocation, [radarLatLng.lat, radarLatLng.lng]);
                gl.uniform2fv(this.minmaxLocation, [cmin, cmax]);
                gl.uniform1i(this.textureLocation, 0);

                gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
                gl.enableVertexAttribArray(this.positionLocation);
                gl.vertexAttribPointer(this.positionLocation, 2, gl.FLOAT, false, 0, 0);

                gl.bindBuffer(gl.ARRAY_BUFFER, this.colorBuffer);
                gl.enableVertexAttribArray(this.colorLocation);
                gl.vertexAttribPointer(this.colorLocation, 1, gl.FLOAT, false, 0, 0);

                gl.bindTexture(gl.TEXTURE_2D, imagetexture);
                gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, imagedata);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

                gl.enable(gl.BLEND);
                gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
                gl.drawArrays(gl.TRIANGLES, 0, vertexF32.length / 2);
                // var endTime = new Date()
                // console.log(scanKey + " - Rendered in: " + (endTime - initTime))
            }
        }

        if (this.map.getLayer(this.scanKey)) {
            this.map.removeLayer(this.scanKey)
        }

        this.map.addLayer(layer, 'road-path');
        this.map.setLayoutProperty(this.scanKey, 'visibility', 'none')

        if (this.shouldRenderInitially) {
            this.render()
        }
    }

    render() {
        if (this.map.getLayer(this.scanKey)) {
            this.map.setLayoutProperty(this.scanKey, 'visibility', 'visible')
        }
    }

    hide() {
        if (this.map.getLayer(this.scanKey)) {
            this.map.setLayoutProperty(this.scanKey, 'visibility', 'none')
        }
    }

    async destroy() {
        this.map.removeLayer(this.scanKey)
        // console.log(await this.map.style._layers)
    }

}