const code = 59;
const abbreviation = ['NHI'];
const description = 'Hail Index';
const formatter = require('./formatter');

// 124 Nmi, Geographic and Non-geographic alphanumeric

module.exports = {
	code,
	abbreviation,
	description,
	formatter,

	productDescription: {
	},
};
