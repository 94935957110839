import axios from "axios";
import moment from "moment";
import mapboxgl from 'mapbox-gl';
import async from "async";

import { xmlToJson, scansFromBucketJsonResponse } from "./utils";
import RadarScan from "./RadarScan";
import { loadRadarFile } from "./Helpers"

var proxyUrl = "https://data-agent.wxview.io/index.php?"

export default class RadarToolkit {

    constructor() {
        this.activeRadar = null;
        this.loadedScans = [];

        this.radarMetaData = {};
        this.radarCache = null;

        this.animation = {
            activeScanId: 0,
            animating: false,
            interval: null,
            timeout: null,
            activeTimestamp: null,
        };

        this.numOfScans = 1;
        this.numOfScansToLoad = 6;
        this.isLoaded = false;

        this.activatedRadarLocation = {}

        this.loadingScans = false;
    }

    async initializeMap() {

        mapboxgl.accessToken = 'pk.eyJ1IjoiY3JheXRvciIsImEiOiJjaWppMDZzZWgwMnZndWJtNWhubGN0NTNmIn0.MkJia5DTwEBGhWqQukG3HA';
        this.map = new mapboxgl.Map({
            container: 'map',
            // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
            style: 'mapbox://styles/craytor/clhw5gsfg00x201p8h1vphabu', // mapbox://styles/mapbox/streets-v12
            zoom: 4,
            center: [-86.31068643201401, 30.00709390974149],
            projection: 'mercator',
            // preserveDrawingBuffer: true, // a way to prevent this? https://github.com/mapbox/mapbox-gl-js/issues/2766
            keyboardPanDelta: 0,
        });

        await this.map.once('load');
    }

    async initializeCache() {
        this.radarCache = await caches.open('radar-cache')
    }

    mapboxMap() {
        return this.map;
    }

    // Adds Radar Sites to Map
    async loadRadarStations() {
        let res = await axios.get('https://api.weather.gov/radar/stations');
        let geoJsonStations = res.data;

        geoJsonStations.features.forEach((station) => {
            if (station.properties.rda !== null) {
                station.properties.latest_update_unix_ts = moment.utc(station.properties.rda.timestamp).unix();
            } else {
                station.properties.latest_update_unix_ts = 0;
            }
            if (this.radarMetaData.stationId && this.radarMetaData.stationId == station.properties.stationId) {
                this.radarMetaData = station.properties;
            }
        })

        if (this.map.getSource('radar-sites')) {
            this.map.getSource('radar-sites').setData(geoJsonStations);
            this.updateRadarSiteIconLayoutProperty();
            return null;
        }

        console.log('radar site layer dne')

        this.map.addSource('radar-sites', {
            type: 'geojson',
            data: geoJsonStations
        })

        this.map.addLayer({
            'id': 'radar-sites',
            'type': 'symbol',
            'source': 'radar-sites',
            'layout': {
                'text-field': ['get', 'id'],
                'icon-text-fit': 'both',
                'text-size': 12,
                'text-font': ["Arial Unicode MS Bold"],
                'icon-text-fit-padding': [4, 8, 4, 8],
            },
            'paint': {
                'text-color': "#fff",
                'icon-halo-width': 20,
                'icon-halo-color': "#fff"
            },
            'filter': ['in', 'stationType', 'WSR-88D', 'TDWR']
        });

        this.updateRadarSiteIconLayoutProperty();

        this.map.on('click', 'radar-sites', (e) => {
            var stationId = e.features[0].properties.id;
            this.radarMetaData = e.features[0].properties;
            this.activatedRadarLocation = e.features[0].geometry.coordinates
            this.activeRadar = stationId;


            this.activateRadar(stationId, 'N0B', null)
            this.flyToLocation()
        })

        this.map.on('mouseenter', 'radar-sites', (e) => {
            this.map.getCanvas().style.cursor = 'pointer'
        })

        this.map.on('mouseleave', 'radar-sites', (e) => {
            this.map.getCanvas().style.cursor = ''
        })

        this.map.on('moveend', () => {
            // const features = this.map.queryRenderedFeatures({ layers: ['lightning-layer'] });
            // console.log(features.length)
        })
    }

    updateRadarSiteIconLayoutProperty() {
        let onlineSinceUnixTimestamp = moment.utc().subtract(20, "minutes").unix()
        this.map.setLayoutProperty('radar-sites', 'icon-image', [
            "case",
            ["all", ["==", ["get", "id"], this.activeRadar], [">", ["get", "latest_update_unix_ts"], onlineSinceUnixTimestamp]],
            'RadarSelected',
            ["<", ["get", "latest_update_unix_ts"], onlineSinceUnixTimestamp],
            'RadarOffline',
            'RadarUnselected'
        ]);
    }

    getScansInChronologicalOrder() {
        return this.loadedScans.sort((a, b) => {
            return new Date(a.scanDateTime) - new Date(b.scanDateTime)
        })
    }

    updateScansToLoad(scansToLoad) {
        if (this.loadedScans.length < scansToLoad) {
            this.numOfScansToLoad = scansToLoad;
            this.loadMultipleRadarScans()
        } else {
            // remove scans
            this.numOfScansToLoad = scansToLoad;
        }
    }

    // async activateRadar(site, product = 'N0B', date = null) {
    //     console.log(site, product, date)
    // }
    // activates a radar
    async activateRadar(site, product = 'N0B', date = null) {
        console.log(site, product, date)

        this.loadingScans = true;

        // Remove existing scans.
        if (this.activeRadar !== null) {
            this.removeAllScans();
        }

        this.activeRadar = site;

        if (this.radarMetaData.stationType !== "WSR-88D") {
            this.activeRadar = null;
            this.radarMetaData = {};
            this.loadingScans = false;
            return null;
        }
        this.updateRadarSiteIconLayoutProperty();

        var siteParsed = site.slice(1)

        var now = moment().utc();
        if (date !== null) {
            now = moment.parse(date);
        }

        var year = now.format('YYYY');
        var month = now.format('MM');
        var day = now.format('DD')

        // https://docs.aws.amazon.com/AmazonS3/latest/API/API_ListObjectsV2.html
        var urlBase = "https://unidata-nexrad-level3.s3.amazonaws.com/";
        var urlPrefInfo = '?list-type=2&prefix=';
        var filenamePrefix = `${siteParsed}_${product}_${year}_${month}_${day}`;
        var fullURL = `${urlBase}${urlPrefInfo}${filenamePrefix}`


        let res = await axios.get(proxyUrl + fullURL)
        let bucketResponseData = xmlToJson(res.data)
        let scans = scansFromBucketJsonResponse(bucketResponseData)

        if (scans.length == -0) {
            // alert('Error: Radar is either down or no scans are currently available.');
            return;
        }

        let scanToLoad = scans[scans.length - 1];

        let loadedScan = new RadarScan(urlBase, scanToLoad.key, scanToLoad.dateTime, 3, this.map, this.radarCache);
        await loadedScan.init(true);
        this.animation.activeTimestamp = scanToLoad.scanDateTime;

        this.updateLightningLayoutProperty();

        this.loadedScans = [loadedScan]
        this.loadingScans = false;

    }

    async loadMultipleRadarScans(product = 'N0B', date = null) {
        this.loadingScans = true;
        var site = this.activeRadar;
        var site = site.slice(1)

        var now = moment().utc();
        if (date !== null) {
            now = moment.parse(date);
        }

        var year = now.format('YYYY');
        var month = now.format('MM');
        var day = now.format('DD')

        // https://docs.aws.amazon.com/AmazonS3/latest/API/API_ListObjectsV2.html
        var urlBase = "https://unidata-nexrad-level3.s3.amazonaws.com/";
        var urlPrefInfo = '?list-type=2&prefix=';
        var filenamePrefix = `${site}_${product}_${year}_${month}_${day}`;
        var fullURL = `${urlBase}${urlPrefInfo}${filenamePrefix}`


        let res = await axios.get(proxyUrl + fullURL)
        let bucketResponseData = xmlToJson(res.data)
        let scans = scansFromBucketJsonResponse(bucketResponseData)


        let scansToLoad = [];
        let downloadTasks = [];

        for (var i = 1; i <= this.numOfScansToLoad; i++) {
            let selectedScan = scans[scans.length - i];

            if (!this.loadedScans.some(obj => {
                return obj.scanKey == selectedScan.key;
            })) {
                console.log('loading ' + selectedScan.key)
                scansToLoad.push(selectedScan)
                var _this = this;
                downloadTasks.push(function (callback = () => { }) {
                    console.log(urlBase, selectedScan, i)
                    var start = new Date()
                    let loadedScan = new RadarScan(urlBase, selectedScan.key, selectedScan.dateTime, 3, _this.map, _this.radarCache);
                    loadedScan.init(i == 0).then(() => {
                        console.log('returned!')
                        _this.loadedScans.push(loadedScan)
                        console.log(selectedScan.key + ": Created in " + (new Date() - start) + " ms")
                        callback(null)
                    })

                })
            }

        }

        async.parallelLimit(downloadTasks, 4, (err, results) => {
            console.log('done', err, results)
            this.loadingScans = false;
        })
    }

    loadScan(urlBase, scan, index) {
        console.log(urlBase, scan, index)
        var start = new Date()
        let loadedScan = new RadarScan(urlBase, scan.key, scan.dateTime, 3, this.map, this.radarCache);
        loadedScan.init(index == 0);
        this.addScan(loadedScan)
        console.log(scan.key + ": Created in " + (new Date() - start) + " ms")
    }

    addScan(loadedScan) {
        this.loadedScans.push(loadedScan)
    }

    startAnimation() {
        if (this.activeRadar == null) {
            return alert("Select a radar site to get started.");
        }
        console.log('this.loadedScans.length', this.loadedScans.length)
        console.log('this.numOfScansToLoad', this.numOfScansToLoad)
        if (this.loadedScans.length < this.numOfScansToLoad - 1 && !this.isLoaded) {
            console.log('Checking to see if enough radar images exist.')
            this.loadMultipleRadarScans()
            this.isLoaded = true;
        }

        this.animation.animating = true;
        let scans = this.getScansInChronologicalOrder();


        // Stop all animations
        for (var scanId in scans) {
            scans[scanId].hide();
        }

        this.animation.activeScanId = 0;
        scans[this.animation.activeScanId].render();

        this.animation.interval = setInterval(() => {
            // console.log(this.animation.activeScanId, scans.length)

            if (this.animation.activeScanId !== null) {
                scans[this.animation.activeScanId].hide();
            }

            if (this.animation.activeScanId == null) {
                this.animation.activeScanId = 0;
            } else if (this.animation.activeScanId + 1 < scans.length) {
                this.animation.activeScanId++;
            } else {
                this.animation.activeScanId = 0;
            }

            this.animation.activeTimestamp = scans[this.animation.activeScanId].scanDateTime;
            this.updateLightningLayoutProperty();
            scans[this.animation.activeScanId].render();


            if (this.animation.activeScanId == scans.length - 1) {
                clearInterval(this.animation.interval);
                this.animation.interval = null;
                this.animation.timeout = setTimeout(() => {
                    this.startAnimation();
                    this.animation.timeout = null;
                }, 5000)
            }

        }, 50)
    }

    toggleAnimation() {
        if (this.animation.animating) {
            this.stopAnimation();
        } else {
            this.startAnimation();
        }
    }

    stopAnimation() {
        clearInterval(this.animation.interval);
        clearTimeout(this.animation.timeout)
        this.animation.interval = null;
        this.animation.animating = false;
        this.animation.timeout = null;
    }

    nextFrame() {
        clearInterval(this.animation.interval);
        clearTimeout(this.animation.timeout)
        this.animation.interval = null;
        this.animation.animating = false;
        this.animation.timeout = null;

        let scans = this.getScansInChronologicalOrder();

        if (this.animation.activeScanId + 1 < scans.length) {
            scans[this.animation.activeScanId].hide();
            this.animation.activeScanId++;
            scans[this.animation.activeScanId].render();
            this.animation.activeTimestamp = scans[this.animation.activeScanId].scanDateTime;
            this.updateLightningLayoutProperty();
        }

    }

    previousFrame() {
        clearInterval(this.animation.interval);
        clearTimeout(this.animation.timeout)
        this.animation.interval = null;
        this.animation.animating = false;
        this.animation.timeout = null;

        let scans = this.getScansInChronologicalOrder();

        if (this.animation.activeScanId - 1 >= 0) {
            scans[this.animation.activeScanId].hide();
            this.animation.activeScanId--;
            scans[this.animation.activeScanId].render();
            this.animation.activeTimestamp = scans[this.animation.activeScanId].scanDateTime;
            this.updateLightningLayoutProperty();
        }

    }

    skipToEndOfAnimation() {
        clearInterval(this.animation.interval);
        clearTimeout(this.animation.timeout)
        this.animation.interval = null;
        this.animation.animating = false;
        this.animation.timeout = null;

        var scans = this.getScansInChronologicalOrder();
        this.map.setLayoutProperty(scans[this.animation.activeScanId].scanKey, 'visibility', 'none')
        this.animation.activeScanId = scans.length - 1;
        this.animation.scanDateTime = scans[this.animation.activeScanId].scanDateTime;
        this.updateLightningLayoutProperty();
        this.map.setLayoutProperty(scans[this.animation.activeScanId].scanKey, 'visibility', 'visible')
    }

    skipToBeginningOfAnimation() {
        clearInterval(this.animation.interval);
        clearTimeout(this.animation.timeout)
        this.animation.interval = null;
        this.animation.animating = false;
        this.animation.timeout = null;

        var scans = this.getScansInChronologicalOrder();
        this.map.setLayoutProperty(scans[this.animation.activeScanId].scanKey, 'visibility', 'none')
        this.animation.activeScanId = 0;
        this.animation.scanDateTime = scans[this.animation.activeScanId].scanDateTime;
        this.updateLightningLayoutProperty();
        this.map.setLayoutProperty(scans[this.animation.activeScanId].scanKey, 'visibility', 'visible')
    }

    getActiveScanTimestamp() {
        try {
            return this.getScansInChronologicalOrder()[this.animation.activeScanId].scanDateTime;
        } catch (e) {
            return null;
        }
    }

    getScansCount() {
        return this.getScansInChronologicalOrder().length - 1;
    }

    removeAllScans() {
        for (var scanId in this.loadedScans) {
            this.loadedScans[scanId].destroy();
            this.loadedScans[scanId] = null;
            console.log(this.loadedScans)
        }
        this.loadedScans = [];
    }

    addLightning() {
        // URL: https://utility.arcgis.com/usrsvcs/servers/a99a3d10fbf64f13897c8165d5393fca/rest/services/Severe/Lightning_CONUS/MapServer/0/query?where=(1%3D1)%20AND%20(1%3D1)&returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=*&f=geojson
        this.map.addSource('lightning-source', {
            type: 'geojson',
            data: 'https://utility.arcgis.com/usrsvcs/servers/a99a3d10fbf64f13897c8165d5393fca/rest/services/Severe/Lightning_CONUS/MapServer/0/query?where=(1%3D1)%20AND%20(1%3D1)&returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=*&f=geojson'
        })

        let now = moment().unix() * 1000;
        console.log(now)

        this.map.addLayer({
            id: "lightning-layer",
            type: "circle",
            source: "lightning-source",
            paint: {
                "circle-color": ["interpolate", ["linear"], ["/", ["-", now, ["get", "datetime"]], 1800], .1, "#fff", 1, "#696969"],
                "circle-radius": ["interpolate", ["linear"], ["zoom"], 6, 3, 13, 8],
                "circle-opacity": ["case", [">=", ["get", "datetime"], now], 0, 1],
                "circle-stroke-color": "#ebebeb",
                "circle-stroke-width": ["interpolate", ["linear"], ["zoom"], 6, .25, 13, 1.5],
                "circle-stroke-opacity": ["case", [">=", ["get", "datetime"], now], 0, 1]
            },
            layout: {
                visibility: 'visible'
            },
        })
    }

    updateLightningLayoutProperty() {
        var timestamp = moment.utc(this.animation.activeTimestamp).valueOf()
        // console.log(timestamp)

        this.map.setPaintProperty(
            'lightning-layer',
            'circle-opacity',
            ["case", [">=", ["get", "datetime"], timestamp], 0, 1],
        );
        this.map.setPaintProperty(
            'lightning-layer',
            'circle-color',
            ["interpolate", ["linear"], ["/", ["-", timestamp, ["get", "datetime"]], 1800 * 1000], .1, "#fff", 1, "#696969"],
        );
        this.map.setPaintProperty(
            'lightning-layer',
            'circle-stroke-opacity',
            ["case", [">=", ["get", "datetime"], timestamp], 0, 1]
        );
    }

    flyToLocation() {
        this.map.flyTo({ center: [this.activatedRadarLocation[0], this.activatedRadarLocation[1]], zoom: 6.5, speed: 1.75, essential: true });
    }
}