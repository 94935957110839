const ProductColors = {
    DVL: {
        "colors-testing": [
            0
        ],
        "colors": [
            "rgb(120, 120, 120)",
            "rgb(0, 236, 236)",
            "rgb(1, 160, 246)",
            "rgb(0, 0, 246)",
            "rgb(0, 255, 0)",
            "rgb(0, 200, 0)",
            "rgb(0, 144, 0)",
            "rgb(255, 255, 0)",
            "rgb(231, 192, 0)",
            "rgb(255, 144, 0)",
            "rgb(255, 0, 0)",
            "rgb(214, 0, 0)",
            "rgb(192, 0, 0)",
            "rgb(255, 0, 255)",
            "rgb(153, 85, 201)",
            "rgb(255, 255, 255)",
            "rgb(235, 235, 235)"
        ],
        "values": [
            0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80
        ],
        "divider": "/(90.0)"
    },
    HHC: {
        "colors-testing": [
            "#9C9C9C",
            "#767676",
            "#FFB0B0",
            "#00FFFF",
            "#0090FF",
            "#00FB90",
            "#00BB00",
            "#D0D060",
            "#D28484",
            "#FF0000",
            "#A01414",
            "#FFFF00",
            "#FFFFFF",
            "#E700FF",
            "#77007D"
        ],
        "colors": [
            "rgb(156, 156, 156)",
            "rgb(156, 156, 156)",
    
            "rgb(118, 118, 118)",
            "rgb(118, 118, 118)",
    
            "rgb(243, 179, 178)",
            "rgb(243, 179, 178)",
    
            "rgb(117, 250, 243)",
            "rgb(117, 250, 243)",
    
            "rgb(63, 141, 247)",
            "rgb(63, 141, 247)",
    
            "rgb(115, 247, 154)",
            "rgb(115, 247, 154)",
    
            "rgb(84, 184, 54)",
            "rgb(84, 184, 54)",
    
            "rgb(208, 207, 112)",
            "rgb(208, 207, 112)",
    
            "rgb(199, 135, 134)",
            "rgb(199, 135, 134)",
    
            "rgb(234, 51, 36)",
            "rgb(234, 51, 36)",
    
            "rgb(147, 37, 30)",
            "rgb(147, 37, 30)",
    
            "rgb(255, 254, 84)",
            "rgb(255, 254, 84)",
    
            "rgb(0, 0, 0)",
            "rgb(0, 0, 0)",
    
            "rgb(212, 45, 246)",
            "rgb(212, 45, 246)",
    
            "rgb(109, 18, 121)",
            "rgb(109, 18, 121)"
        ],
        "values": [
            0, 10,
            10, 20,
            20, 30,
            30, 40,
            40, 50,
            50, 60,
            60, 70,
            70, 80,
            80, 90,
            90, 100,
            100, 110,
            110, 120,
            120, 130,
            130, 140,
            140, 150
        ],
        "divider": "/(155.0)"
    },
    N0B: {
        "colors-testing": [
            "#646464",
            "#ccffff",
            "#cc99cc",
            "#996699",
            "#663366",
            "#999966",
    
            "#646464",
            "#04e9e7",
            "#019ff4",
            "#0300f4",
            "#02fd02",
            "#01c501",
            "#008e00",
            "#fdf802",
            "#e5bc00",
            "#fd9500",
            "#fd0000",
            "#d40000",
            "#bc0000",
            "#f800fd",
            "#9854c6",
            "#fdfdfd",
    
            -30, -25, -20, -15, -10, -5, 0,
            5, 10, 15, 20, 25, 30, 35, 40,
            45, 50, 55, 60, 65, 70, 75,
    
    
            "quadweather",
            "#242424", "#242424", "#0FCBFF", "#1D9E00", "#95F44C", "#FFF700", "#FF9F0F", "#EB7900", "#FF1F1F", "#AD1100", "#FFB8F5", "#CA57FF", "#4A009E",
            0, 10, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 80
        ],
        "colors": [
            "rgb(116, 78, 173)",
            "rgb(147, 141, 117)",
    
            "rgb(150, 145, 83)",
            "rgb(210, 212, 180)",
    
            "rgb(204, 207, 180)",
            "rgb(65, 91, 158)",
    
            "rgb(67, 97, 162)",
            "rgb(106, 208, 225)",
    
            "rgb(111, 214, 232)",
            "rgb(53, 213, 91)",
    
            "rgb(17, 213, 24)",
            "rgb(9, 94, 9)",
    
            "rgb(29, 104, 9)",
            "rgb(234, 210, 4)",
    
            "rgb(255, 226, 0)",
            "rgb(255, 128, 0)",
    
            "rgb(255, 0, 0)",
            "rgb(113, 0, 0)",
    
            "rgb(255, 255, 255)",
            "rgb(255, 146, 255)",
    
            "rgb(255, 117, 255)",
            "rgb(225, 11, 227)",
    
            "rgb(178, 0, 255)",
            "rgb(99, 0, 214)",
    
            "rgb(5, 236, 240)",
            "rgb(1, 32, 32)",
    
            "rgb(1, 32, 32)",
            "rgb(1, 32, 32)"
        ],
        "values": [
            -30, -20,
            -20, -10,
            -10, 10,
            10, 18,
            18, 22,
            22, 35,
            35, 40,
            40, 50,
            50, 60,
            60, 65,
            65, 70,
            70, 75,
            75, 85,
            85,
            95
        ],
        "divider": "/(125.0)"
    },
    N0C: {
        "colors-testing": [
            0
        ],
        "colors": [
            "#000000", "#949494", "#7593FF", "#0045BD", "#ADF4FF", "#00FA32", "#FFD53D", "#F01000", "#C20047", "#FFB8D8", "#FFEBF2"
        ],
        "values": [
            0.2, 0.4, 0.55, 0.65, 0.8, 0.85, 0.95, 0.975, 1, 1.04, 1.05
        ],
        "divider": "/(255.0)"
    },
    N0G: {
        "colors-testing": [
            0
        ],
        // Product:bv
        // units: KTS
        // step: 5
        // scale: 1.9426

        // color: 0 137 117 122 130 51 59 
        // color: 10 109 0 0 242 0 7
        // color: 40 249 51 76 255 149 207
        // color: 55 253 160 201 255 232 172
        // color: 60 253 228 160 253 149 83 
        // color: 80 254 142 80 110 14 9
        // color: 120 110 14 9

        // color: -10 78 121 76 116 131 112
        // color: -40 3 234 2  0 100 0
        // color: -50 181 237 239 2 241 3
        // color: -70 47 222 226 181 237 239 
        // color: -80 30 111 188 40 204 220
        // color: -90  24 39 165 30 111 188
        // color: -100 110 3 151 22 13 156
        // color: -120 252 0 130 109 2 150 
        // RF: 139 0 218

        // 'rgb(000, 000, 000)',
        "colors": [
            'rgb(252, 0, 130)', // -120
            'rgb(109, 2, 150)', // -100
            'rgb(110, 3, 151)', // -100
            'rgb(22, 13, 156)', // -90
            'rgb(24, 39, 165)', // -90
            'rgb(30, 111, 188)', // -80
            'rgb(30, 111, 188)', // -80
            'rgb(40, 204, 220)', // -70
            'rgb(47, 222, 226)', // -70
            'rgb(181, 237, 239)', // -50
            'rgb(181, 237, 239)', // -50
            'rgb(2, 241, 3)', // -40
            'rgb(3, 234, 2)', // -40

            'rgb(0, 100, 0)', // -10
            'rgb(78, 121, 76)', // -10
            'rgb(116, 131, 112)', // 0
            'rgb(137, 117, 122)', // 0
            'rgb(130, 51, 59)', // 10
            'rgb(109, 0, 0)', // 10

            'rgb(242, 0, 7)', // 40
            'rgb(249, 51, 76)', // 40
            'rgb(255, 149, 207)', // 55
            'rgb(253, 160, 201)', // 55
            'rgb(255, 232, 172)', // 60
            'rgb(253, 228, 160)', // 60
            'rgb(253, 149, 83)', // 80
            'rgb(254, 142, 80)', // 80
            'rgb(110, 14, 9)', // 120
            'rgb(110, 14, 9)', // 120
        ],
        "values": [
            -120, -100,
            -100, -90,
            -90, -80,
            -80, -70,
            -70, -50,
            -50, -40,
            -40, -10,
            -10, 0,
            0, 10,
            10, 40,
            40, 55,
            55, 60,
            60, 80,
            80, 120,
            120
        ],
        "divider": "/(135.0)"
    },
    N0H: {
        "colors-testing": [
            "#9C9C9C",
            "#767676",
            "#FFB0B0",
            "#00FFFF",
            "#0090FF",
            "#00FB90",
            "#00BB00",
            "#D0D060",
            "#D28484",
            "#FF0000",
            "#A01414",
            "#FFFF00",
            "#FFFFFF",
            "#E700FF",
            "#77007D"
        ],
        "colors": [
            "rgb(156, 156, 156)",
            "rgb(156, 156, 156)",
    
            "rgb(118, 118, 118)",
            "rgb(118, 118, 118)",
    
            "rgb(243, 179, 178)",
            "rgb(243, 179, 178)",
    
            "rgb(117, 250, 243)",
            "rgb(117, 250, 243)",
    
            "rgb(63, 141, 247)",
            "rgb(63, 141, 247)",
    
            "rgb(115, 247, 154)",
            "rgb(115, 247, 154)",
    
            "rgb(84, 184, 54)",
            "rgb(84, 184, 54)",
    
            "rgb(208, 207, 112)",
            "rgb(208, 207, 112)",
    
            "rgb(199, 135, 134)",
            "rgb(199, 135, 134)",
    
            "rgb(234, 51, 36)",
            "rgb(234, 51, 36)",
    
            "rgb(147, 37, 30)",
            "rgb(147, 37, 30)",
    
            "rgb(255, 254, 84)",
            "rgb(255, 254, 84)",
    
            "rgb(0, 0, 0)",
            "rgb(0, 0, 0)",
    
            "rgb(212, 45, 246)",
            "rgb(212, 45, 246)",
    
            "rgb(109, 18, 121)",
            "rgb(109, 18, 121)"
        ],
        "values": [
            0, 10,
            10, 20,
            20, 30,
            30, 40,
            40, 50,
            50, 60,
            60, 70,
            70, 80,
            80, 90,
            90, 100,
            100, 110,
            110, 120,
            120, 130,
            130, 140,
            140, 150
        ],
        "divider": "/(155.0)"
    },
    N0S: {
        "colors-testing": [
            0
        ],
        "colors": [
            "rgb(155, 31, 139)",
            "rgb(155, 31, 139)",
    
            "rgb(48, 7, 147)",
            "rgb(48, 7, 147)",
    
            "rgb(64, 128, 189)",
            "rgb(64, 128, 189)",
    
            "rgb(133, 226, 231)",
            "rgb(133, 226, 231)",
    
            "rgb(163, 240, 186)",
            "rgb(163, 240, 186)",
    
            "rgb(96, 209, 62)",
            "rgb(96, 209, 62)",
    
            "rgb(56, 127, 33)",
            "rgb(56, 127, 33)",
    
            "rgb(117, 131, 114)",
            "rgb(117, 131, 114)",
    
            "rgb(121, 21, 13)",
            "rgb(121, 21, 13)",
    
            "rgb(201, 43, 30)",
            "rgb(201, 43, 30)",
    
            "rgb(235, 123, 169)",
            "rgb(235, 123, 169)",
    
            "rgb(251, 229, 166)",
            "rgb(251, 229, 166)",
    
            "rgb(242, 162, 103)",
            "rgb(242, 162, 103)",
    
            "rgb(196, 104, 67)",
            "rgb(196, 104, 67)",
    
            "rgb(115, 20, 198)",
            "rgb(115, 20, 198)"
        ],
        "values": [
            1, 2,
            2, 3,
            3, 4,
            4, 5,
            5, 6,
            6, 7,
            7, 8,
            8, 9,
            9, 10,
            10, 11,
            11, 12,
            12, 13,
            13, 14,
            14, 15,
            15, 16
        ],
        "divider": "/(16.0)"
    },
    N0U: {
        "colors-testing": [
            0
        ],
        "colors": [
            'rgb(252, 0, 130)', // -120
            'rgb(109, 2, 150)', // -100
            'rgb(110, 3, 151)', // -100
            'rgb(22, 13, 156)', // -90
            'rgb(24, 39, 165)', // -90
            'rgb(30, 111, 188)', // -80
            'rgb(30, 111, 188)', // -80
            'rgb(40, 204, 220)', // -70
            'rgb(47, 222, 226)', // -70
            'rgb(181, 237, 239)', // -50
            'rgb(181, 237, 239)', // -50
            'rgb(2, 241, 3)', // -40
            'rgb(3, 234, 2)', // -40

            'rgb(0, 100, 0)', // -10
            'rgb(78, 121, 76)', // -10
            'rgb(116, 131, 112)', // 0
            'rgb(137, 117, 122)', // 0
            'rgb(130, 51, 59)', // 10
            'rgb(109, 0, 0)', // 10

            'rgb(242, 0, 7)', // 40
            'rgb(249, 51, 76)', // 40
            'rgb(255, 149, 207)', // 55
            'rgb(253, 160, 201)', // 55
            'rgb(255, 232, 172)', // 60
            'rgb(253, 228, 160)', // 60
            'rgb(253, 149, 83)', // 80
            'rgb(254, 142, 80)', // 80
            'rgb(110, 14, 9)', // 120
            'rgb(110, 14, 9)', // 120
        ],
        "values": [
            -120, -100,
            -100, -90,
            -90, -80,
            -80, -70,
            -70, -50,
            -50, -40,
            -40, -10,
            -10, 0,
            0, 10,
            10, 40,
            40, 55,
            55, 60,
            60, 80,
            80, 120,
            120
        ],
        "divider": "/(135.0)"
    },
    N0X: {
        "colors-testing": [
            0
        ],
        "colors": [
            "rgb(0, 0, 0)",
            "rgb(55, 55, 55)",
            "rgb(110, 110, 110)",
            "rgb(165, 165, 165)",
            "rgb(220, 220, 220)",
            "rgb(142, 121, 181)",
            "rgb(10, 10, 155)",
            "rgb(68, 248, 212)",
            "rgb(90, 221, 98)",
            "rgb(255, 255, 100)",
            "rgb(220, 10, 5)",
            "rgb(175, 0, 0)",
            "rgb(240, 120, 180)",
            "rgb(255, 255, 255)",
            "rgb(145, 45, 150)"
        ],
        "values": [
            -8, -6, -4, -2, 0, 0, 0.25, 1, 1.5, 2, 3, 4, 5, 6, 8
        ],
        "divider": "/(255.0)"
    },
    NSW: {
        "colors-testing": [
            0
        ],
        "colors": [
            "rgb(118, 118, 118)",
            "rgb(137, 137, 137)",
            "rgb(156, 156, 156)",
            "rgb(78, 171, 78)",
            "rgb(0, 187, 0)",
            "rgb(127, 93, 0)",
            "rgb(255, 0, 0)",
            "rgb(231, 56, 0)",
            "rgb(208, 112, 0)",
            "rgb(231, 183, 0)",
            "rgb(255, 255, 0)"
        ],
        "values": [
            0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30
        ],
        "divider": "/(10.0)"
    },
    NXQ: {
        "colors-testing": [
            "#646464",
            "#ccffff",
            "#cc99cc",
            "#996699",
            "#663366",
            "#999966",
    
            "#646464",
            "#04e9e7",
            "#019ff4",
            "#0300f4",
            "#02fd02",
            "#01c501",
            "#008e00",
            "#fdf802",
            "#e5bc00",
            "#fd9500",
            "#fd0000",
            "#d40000",
            "#bc0000",
            "#f800fd",
            "#9854c6",
            "#fdfdfd",
    
            -30, -25, -20, -15, -10, -5, 0,
            5, 10, 15, 20, 25, 30, 35, 40,
            45, 50, 55, 60, 65, 70, 75
        ],
        "colors": [
            "rgb(116, 78, 173)",
            "rgb(147, 141, 117)",
    
            "rgb(150, 145, 83)",
            "rgb(210, 212, 180)",
    
            "rgb(204, 207, 180)",
            "rgb(65, 91, 158)",
    
            "rgb(67, 97, 162)",
            "rgb(106, 208, 225)",
    
            "rgb(111, 214, 232)",
            "rgb(53, 213, 91)",
    
            "rgb(17, 213, 24)",
            "rgb(9, 94, 9)",
    
            "rgb(29, 104, 9)",
            "rgb(234, 210, 4)",
    
            "rgb(255, 226, 0)",
            "rgb(255, 128, 0)",
    
            "rgb(255, 0, 0)",
            "rgb(113, 0, 0)",
    
            "rgb(255, 255, 255)",
            "rgb(255, 146, 255)",
    
            "rgb(255, 117, 255)",
            "rgb(225, 11, 227)",
    
            "rgb(178, 0, 255)",
            "rgb(99, 0, 214)",
    
            "rgb(5, 236, 240)",
            "rgb(1, 32, 32)",
    
            "rgb(1, 32, 32)",
            "rgb(1, 32, 32)"
        ],
        "values": [
            -30, -20,
            -20, -10,
            -10, 10,
            10, 18,
            18, 22,
            22, 35,
            35, 40,
            40, 50,
            50, 60,
            60, 65,
            65, 70,
            70, 75,
            75, 85,
            85,
            95
        ],
        "divider": "/(125.0)"
    },
    PHI: {
        "colors-testing": [
            "rgb(150, 150, 150)", "rgb(75, 0, 0)", "rgb(235, 120, 185)", "rgb(170, 149, 203)", "rgb(98, 255, 250)", "rgb(20, 185, 50)", "rgb(10, 255, 10)", "rgb(255, 255, 0)", "rgb(255, 120, 20)", "rgb(164, 72, 0)"
        ],
        "colors": [
            "rgb(255, 255, 255)",
            "rgb(210, 210, 180)",
            "rgb(10, 20, 95)",
            "rgb(0, 255, 0)",
            "rgb(30, 100, 0)",
            "rgb(255, 255, 0)",
            "rgb(255, 125, 0)",
            "rgb(90, 0, 0)",
            "rgb(255, 140, 255)"
        ],
        "values": [
            0, 15, 30, 45, 60, 75, 90, 120, 180
        ],
        "divider": "/(135.0)"
    },
    REF: {
        "colors-testing": [
            "#646464",
            "#ccffff",
            "#cc99cc",
            "#996699",
            "#663366",
            "#999966",
    
            "#646464",
            "#04e9e7",
            "#019ff4",
            "#0300f4",
            "#02fd02",
            "#01c501",
            "#008e00",
            "#fdf802",
            "#e5bc00",
            "#fd9500",
            "#fd0000",
            "#d40000",
            "#bc0000",
            "#f800fd",
            "#9854c6",
            "#fdfdfd",
    
            -30, -25, -20, -15, -10, -5, 0,
            5, 10, 15, 20, 25, 30, 35, 40,
            45, 50, 55, 60, 65, 70, 75
        ],
        "colors": [
            "rgb(116, 78, 173)",
            "rgb(147, 141, 117)",
    
            "rgb(150, 145, 83)",
            "rgb(210, 212, 180)",
    
            "rgb(204, 207, 180)",
            "rgb(65, 91, 158)",
    
            "rgb(67, 97, 162)",
            "rgb(106, 208, 225)",
    
            "rgb(111, 214, 232)",
            "rgb(53, 213, 91)",
    
            "rgb(17, 213, 24)",
            "rgb(9, 94, 9)",
    
            "rgb(29, 104, 9)",
            "rgb(234, 210, 4)",
    
            "rgb(255, 226, 0)",
            "rgb(255, 128, 0)",
    
            "rgb(255, 0, 0)",
            "rgb(113, 0, 0)",
    
            "rgb(255, 255, 255)",
            "rgb(255, 146, 255)",
    
            "rgb(255, 117, 255)",
            "rgb(225, 11, 227)",
    
            "rgb(178, 0, 255)",
            "rgb(99, 0, 214)",
    
            "rgb(5, 236, 240)",
            "rgb(1, 32, 32)",
    
            "rgb(1, 32, 32)",
            "rgb(1, 32, 32)"
        ],
        "values": [
            -30, -20,
            -20, -10,
            -10, 10,
            10, 18,
            18, 22,
            22, 35,
            35, 40,
            40, 50,
            50, 60,
            60, 65,
            65, 70,
            70, 75,
            75, 85,
            85,
            95
        ],
        "divider": "/(125.0)"
    },
    RHO: {
        "colors-testing": [
            0
        ],
        "colors": [
            "#000000", "#949494", "#7593FF", "#0045BD", "#ADF4FF", "#00FA32", "#FFD53D", "#F01000", "#C20047", "#FFB8D8", "#FFEBF2"
        ],
        "values": [
            0.2, 0.4, 0.55, 0.65, 0.8, 0.85, 0.95, 0.975, 1, 1.04, 1.05
        ],
        "divider": ""
    },
    'SW ': {
        "colors-testing": [
            0
        ],
        "colors": [
            "rgb(118, 118, 118)",
            "rgb(137, 137, 137)",
            "rgb(156, 156, 156)",
            "rgb(78, 171, 78)",
            "rgb(0, 187, 0)",
            "rgb(127, 93, 0)",
            "rgb(255, 0, 0)",
            "rgb(231, 56, 0)",
            "rgb(208, 112, 0)",
            "rgb(231, 183, 0)",
            "rgb(255, 255, 0)"
        ],
        "values": [
            0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30
        ],
        "divider": "/(10.0)"
    },
    TVX: {
        "colors-testing": [
            0
        ],
        "colors": [
            'rgb(252, 0, 130)', // -120
            'rgb(109, 2, 150)', // -100
            'rgb(110, 3, 151)', // -100
            'rgb(22, 13, 156)', // -90
            'rgb(24, 39, 165)', // -90
            'rgb(30, 111, 188)', // -80
            'rgb(30, 111, 188)', // -80
            'rgb(40, 204, 220)', // -70
            'rgb(47, 222, 226)', // -70
            'rgb(181, 237, 239)', // -50
            'rgb(181, 237, 239)', // -50
            'rgb(2, 241, 3)', // -40
            'rgb(3, 234, 2)', // -40

            'rgb(0, 100, 0)', // -10
            'rgb(78, 121, 76)', // -10
            'rgb(116, 131, 112)', // 0
            'rgb(137, 117, 122)', // 0
            'rgb(130, 51, 59)', // 10
            'rgb(109, 0, 0)', // 10

            'rgb(242, 0, 7)', // 40
            'rgb(249, 51, 76)', // 40
            'rgb(255, 149, 207)', // 55
            'rgb(253, 160, 201)', // 55
            'rgb(255, 232, 172)', // 60
            'rgb(253, 228, 160)', // 60
            'rgb(253, 149, 83)', // 80
            'rgb(254, 142, 80)', // 80
            'rgb(110, 14, 9)', // 120
            'rgb(110, 14, 9)', // 120
        ],
        "values": [
            -120, -100,
            -100, -90,
            -90, -80,
            -80, -70,
            -70, -50,
            -50, -40,
            -40, -10,
            -10, 0,
            0, 10,
            10, 40,
            40, 55,
            55, 60,
            60, 80,
            80, 120,
            120
        ],
        "divider": "/(135.0)"
    },
    TZL: {
        "colors-testing": [
            "#646464",
            "#ccffff",
            "#cc99cc",
            "#996699",
            "#663366",
            "#999966",
    
            "#646464",
            "#04e9e7",
            "#019ff4",
            "#0300f4",
            "#02fd02",
            "#01c501",
            "#008e00",
            "#fdf802",
            "#e5bc00",
            "#fd9500",
            "#fd0000",
            "#d40000",
            "#bc0000",
            "#f800fd",
            "#9854c6",
            "#fdfdfd",
    
            -30, -25, -20, -15, -10, -5, 0,
            5, 10, 15, 20, 25, 30, 35, 40,
            45, 50, 55, 60, 65, 70, 75,
    
    
            "quadweather",
            "#242424", "#242424", "#0FCBFF", "#1D9E00", "#95F44C", "#FFF700", "#FF9F0F", "#EB7900", "#FF1F1F", "#AD1100", "#FFB8F5", "#CA57FF", "#4A009E",
            0, 10, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 80
        ],
        "colors": [
            "rgb(116, 78, 173)",
            "rgb(147, 141, 117)",
    
            "rgb(150, 145, 83)",
            "rgb(210, 212, 180)",
    
            "rgb(204, 207, 180)",
            "rgb(65, 91, 158)",
    
            "rgb(67, 97, 162)",
            "rgb(106, 208, 225)",
    
            "rgb(111, 214, 232)",
            "rgb(53, 213, 91)",
    
            "rgb(17, 213, 24)",
            "rgb(9, 94, 9)",
    
            "rgb(29, 104, 9)",
            "rgb(234, 210, 4)",
    
            "rgb(255, 226, 0)",
            "rgb(255, 128, 0)",
    
            "rgb(255, 0, 0)",
            "rgb(113, 0, 0)",
    
            "rgb(255, 255, 255)",
            "rgb(255, 146, 255)",
    
            "rgb(255, 117, 255)",
            "rgb(225, 11, 227)",
    
            "rgb(178, 0, 255)",
            "rgb(99, 0, 214)",
    
            "rgb(5, 236, 240)",
            "rgb(1, 32, 32)",
    
            "rgb(1, 32, 32)",
            "rgb(1, 32, 32)"
        ],
        "values": [
            -30, -20,
            -20, -10,
            -10, 10,
            10, 18,
            18, 22,
            22, 35,
            35, 40,
            40, 50,
            50, 60,
            60, 65,
            65, 70,
            70, 75,
            75, 85,
            85,
            95
        ],
        "divider": "/(125.0)"
    },
    TZX: {
        "colors-testing": [
            "#646464",
            "#ccffff",
            "#cc99cc",
            "#996699",
            "#663366",
            "#999966",
    
            "#646464",
            "#04e9e7",
            "#019ff4",
            "#0300f4",
            "#02fd02",
            "#01c501",
            "#008e00",
            "#fdf802",
            "#e5bc00",
            "#fd9500",
            "#fd0000",
            "#d40000",
            "#bc0000",
            "#f800fd",
            "#9854c6",
            "#fdfdfd",
    
            -30, -25, -20, -15, -10, -5, 0,
            5, 10, 15, 20, 25, 30, 35, 40,
            45, 50, 55, 60, 65, 70, 75,
    
    
            "quadweather",
            "#242424", "#242424", "#0FCBFF", "#1D9E00", "#95F44C", "#FFF700", "#FF9F0F", "#EB7900", "#FF1F1F", "#AD1100", "#FFB8F5", "#CA57FF", "#4A009E",
            0, 10, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 80
        ],
        "colors": [
            "rgb(116, 78, 173)",
            "rgb(147, 141, 117)",
    
            "rgb(150, 145, 83)",
            "rgb(210, 212, 180)",
    
            "rgb(204, 207, 180)",
            "rgb(65, 91, 158)",
    
            "rgb(67, 97, 162)",
            "rgb(106, 208, 225)",
    
            "rgb(111, 214, 232)",
            "rgb(53, 213, 91)",
    
            "rgb(17, 213, 24)",
            "rgb(9, 94, 9)",
    
            "rgb(29, 104, 9)",
            "rgb(234, 210, 4)",
    
            "rgb(255, 226, 0)",
            "rgb(255, 128, 0)",
    
            "rgb(255, 0, 0)",
            "rgb(113, 0, 0)",
    
            "rgb(255, 255, 255)",
            "rgb(255, 146, 255)",
    
            "rgb(255, 117, 255)",
            "rgb(225, 11, 227)",
    
            "rgb(178, 0, 255)",
            "rgb(99, 0, 214)",
    
            "rgb(5, 236, 240)",
            "rgb(1, 32, 32)",
    
            "rgb(1, 32, 32)",
            "rgb(1, 32, 32)"
        ],
        "values": [
            -30, -20,
            -20, -10,
            -10, 10,
            10, 18,
            18, 22,
            22, 35,
            35, 40,
            40, 50,
            50, 60,
            60, 65,
            65, 70,
            70, 75,
            75, 85,
            85,
            95
        ],
        "divider": "/(125.0)"
    },
    VEL: {
        "colors-testing": [
            "#ffffff",
            "#FC52FF",
            "#871FFF",
            "#0011CC",
            "#0088CC",
            "#B3F0FF",
    
            "#42FF42",
            "#009402",
            "#A3A3A3",
            "#8A0000",
            "#FF5269",
    
            "#FFB3E0",
            "#FFF1C2",
            "#FF9214",
            "#B85C00",
            "#572100",
            "#000000"
        ],
        "colors": [
            'rgb(252, 0, 130)', // -120
            'rgb(109, 2, 150)', // -100
            'rgb(110, 3, 151)', // -100
            'rgb(22, 13, 156)', // -90
            'rgb(24, 39, 165)', // -90
            'rgb(30, 111, 188)', // -80
            'rgb(30, 111, 188)', // -80
            'rgb(40, 204, 220)', // -70
            'rgb(47, 222, 226)', // -70
            'rgb(181, 237, 239)', // -50
            'rgb(181, 237, 239)', // -50
            'rgb(2, 241, 3)', // -40
            'rgb(3, 234, 2)', // -40

            'rgb(0, 100, 0)', // -10
            'rgb(78, 121, 76)', // -10
            'rgb(116, 131, 112)', // 0
            'rgb(137, 117, 122)', // 0
            'rgb(130, 51, 59)', // 10
            'rgb(109, 0, 0)', // 10

            'rgb(242, 0, 7)', // 40
            'rgb(249, 51, 76)', // 40
            'rgb(255, 149, 207)', // 55
            'rgb(253, 160, 201)', // 55
            'rgb(255, 232, 172)', // 60
            'rgb(253, 228, 160)', // 60
            'rgb(253, 149, 83)', // 80
            'rgb(254, 142, 80)', // 80
            'rgb(110, 14, 9)', // 120
            'rgb(110, 14, 9)', // 120
        ],
        "values": [
            -120, -100,
            -100, -90,
            -90, -80,
            -80, -70,
            -70, -50,
            -50, -40,
            -40, -10,
            -10, 0,
            0, 10,
            10, 40,
            40, 55,
            55, 60,
            60, 80,
            80, 120,
            120
        ],
        "divider": "/(70.0)"
    },
    ZDR: {
        "colors-testing": [
            0
        ],
        "colors": [
            "rgb(0, 0, 0)",
            "rgb(55, 55, 55)",
            "rgb(110, 110, 110)",
            "rgb(165, 165, 165)",
            "rgb(220, 220, 220)",
            "rgb(142, 121, 181)",
            "rgb(10, 10, 155)",
            "rgb(68, 248, 212)",
            "rgb(90, 221, 98)",
            "rgb(255, 255, 100)",
            "rgb(220, 10, 5)",
            "rgb(175, 0, 0)",
            "rgb(240, 120, 180)",
            "rgb(255, 255, 255)",
            "rgb(145, 45, 150)"
        ],
        "values": [
            -8, -6, -4, -2, 0, 0, 0.25, 1, 1.5, 2, 3, 4, 5, 6, 8
        ],
        "divider": "/(20.0)"
    }
}

module.exports = ProductColors;