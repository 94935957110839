<template>
  <div id="map" class="w-full h-full relative">
    <!-- <div class="bg-gray-800 absolute z-50 b-0 top-0 left-0 w-72 rounded-lg overflow-hidden mt-6 ml-6">
      <div class="p-4 py-2">
        <h1 class="text-lg text-white">Map Layers</h1>
      </div>
      <div class="flex flex-col space-y-1.5 overflow-y-scroll px-4" style="max-height: 400px;">
        <button class="px-3 py-1.5 bg-blue-600 text-white rounded-lg focus:outline-none"
          v-for="product in availableProducts" v-on:click="selectedProduct(product)">
          {{ product.name }}
        </button>

      </div>
    </div> -->

    <div class="bg-gray-800 absolute z-50 b-0 bottom-0 left-0 w-96 rounded-lg overflow-hidden mb-6 ml-6">
      <div class="p-4" v-if="radarToolkit !== null">
        <div
          class="bg-red-600 text-white rounded px-3 py-1.5 mb-4 flex flex-row items-center justify-between text-md font-medium"
          v-if="radarToolkit.radarMetaData.latest_update_unix_ts < currentTs">
          Radar offline.
          <i class="fa-regular fa-circle-info"></i>
        </div>
        <div class=" flex flex-row">
          <h1 class="text-lg text-white flex-1">Radar</h1>
          <div>
            <select id="scans" name="scans"
              class="block w-full rounded-md border-0 py-1.5 pl-3 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numOfScansToLoad">
              <option>6</option>
              <option>8</option>
              <option>10</option>
              <option>12</option>
              <option>14</option>
              <option>16</option>
              <option>18</option>
              <option>20</option>
              <option>22</option>
              <option>24</option>
              <option>26</option>
              <option>28</option>
              <option>30</option>
            </select>
          </div>
        </div>

        <div class="flex flex-col space-y-1.5 mt-6" v-if="radarToolkit !== null">
          <div class="flex flex-row justify-between items-center">
            <button class="w-8 h-8 bg-blue-600 text-white rounded-full" v-on:click="radarToolkit.toggleAnimation">
              <i class="fa fa-play" v-if="!radarToolkit.animation.animating && !radarToolkit.loadingScans"></i>
              <i class="fa fa-pause" v-if="radarToolkit.animation.animating && !radarToolkit.loadingScans"></i>
              <i class="fa-solid fa-circle-notch fa-spin" v-if="radarToolkit.loadingScans"></i>
            </button>
            <!-- <button v-on:click="radarToolkit.skipToEndOfAnimation">End</button>
            <button v-on:click="radarToolkit.skipToBeginningOfAnimation">Beginning</button> -->
            <div class="text-gray-200 text-lg font-medium">
              {{ getActiveScanTimestamp() }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="radarToolkit !== null" class="h-1.5 bg-blue-500" :style="{
        'width':
          (radarToolkit.animation.activeScanId / radarToolkit.getScansCount()) * 100 + '%'
      }
        ">

      </div>
    </div>
  </div>
</template>

<script>
// Lightning: https://utility.arcgis.com/usrsvcs/servers/a99a3d10fbf64f13897c8165d5393fca/rest/services/Severe/Lightning_CONUS/MapServer/0/query?where=objectid%3E0&f=geojson
// Lightning: https://www.arcgis.com/home/webmap/viewer.html?webmap=bf197e29f313422684c4f4bcbf6f3fe2 // https://www.arcgis.com/home/item.html?id=130bf9745ddb43d2afe9ee141eb8b9a6
import HelloWorld from './components/HelloWorld.vue'

import moment from "moment";
import axios from "axios";

import RadarToolkit from "./Radar/RadarToolkit"

// import { ScalarFill } from "@sakitam-gis/mapbox-wind"

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data() {
    return {
      currentTs: moment.utc().subtract(20, 'minutes').unix(),
      map: null,
      radarToolkit: null,
      activeSpcLayers: [],
      availableProducts: [
        {
          type: 'SPC_CATEGORICAL',
          name: 'Day 1 Categorical',
          layers: [
            {
              id: 'SPC_CATEGORICAL',
              url: 'https://www.spc.noaa.gov/products/outlook/day1otlk_cat.nolyr.geojson',
            }
          ]
        },
        {
          type: 'SPC_HAZARD',
          name: 'Day 1 Tornado',
          layers: [
            {
              id: 'SPC_HAZARD_TORNADO',
              url: 'https://www.spc.noaa.gov/products/outlook/day1otlk_torn.nolyr.geojson'
            },
            {
              id: 'SPC_HAZARD_TORNADO_SIG',
              url: 'https://www.spc.noaa.gov/products/outlook/day1otlk_sigtorn.nolyr.geojson'
            }
          ]
        },
        {
          type: 'SPC_HAZARD',
          name: 'Day 1 Hail',
          layers: [
            {
              id: 'SPC_HAZARD_HAIL',
              url: 'https://www.spc.noaa.gov/products/outlook/day1otlk_hail.nolyr.geojson'
            },
            {
              id: 'SPC_HAZARD_HAIL_SIG',
              url: 'https://www.spc.noaa.gov/products/outlook/day1otlk_sighail.nolyr.geojson'
            }
          ]
        },
        {
          type: 'SPC_HAZARD',
          name: 'Day 1 Wind',
          layers: [
            {
              id: 'SPC_HAZARD_WIND',
              url: 'https://www.spc.noaa.gov/products/outlook/day1otlk_wind.nolyr.geojson'
            },
            {
              id: 'SPC_HAZARD_WIND_SIG',
              url: 'https://www.spc.noaa.gov/products/outlook/day1otlk_sigwind.nolyr.geojson'
            }
          ]
        },
        {
          type: 'SPC_CATEGORICAL',
          name: 'Day 2 Categorical',
          layers: [
            {
              id: 'SPC_CATEGORICAL',
              url: 'https://www.spc.noaa.gov/products/outlook/day2otlk_cat.nolyr.geojson',
            }
          ]
        },
        {
          type: 'SPC_HAZARD',
          name: 'Day 2 Tornado',
          layers: [
            {
              id: 'SPC_HAZARD_TORNADO',
              url: 'https://www.spc.noaa.gov/products/outlook/day2otlk_torn.nolyr.geojson'
            },
            {
              id: 'SPC_HAZARD_TORNADO_SIG',
              url: 'https://www.spc.noaa.gov/products/outlook/day2otlk_sigtorn.nolyr.geojson'
            }
          ]
        },
        {
          type: 'SPC_HAZARD',
          name: 'Day 2 Hail',
          layers: [
            {
              id: 'SPC_HAZARD_HAIL',
              url: 'https://www.spc.noaa.gov/products/outlook/day2otlk_hail.nolyr.geojson'
            },
            {
              id: 'SPC_HAZARD_HAIL_SIG',
              url: 'https://www.spc.noaa.gov/products/outlook/day2otlk_sighail.nolyr.geojson'
            }
          ]
        },
        {
          type: 'SPC_HAZARD',
          name: 'Day 2 Wind',
          layers: [
            {
              id: 'SPC_HAZARD_WIND',
              url: 'https://www.spc.noaa.gov/products/outlook/day2otlk_wind.nolyr.geojson'
            },
            {
              id: 'SPC_HAZARD_WIND_SIG',
              url: 'https://www.spc.noaa.gov/products/outlook/day2otlk_sigwind.nolyr.geojson'
            }
          ]
        },
        {
          type: 'SPC_CATEGORICAL',
          name: 'Day 3 Categorical',
          layers: [
            {
              id: 'SPC_CATEGORICAL',
              url: 'https://www.spc.noaa.gov/products/outlook/day3otlk_cat.nolyr.geojson'
            },
          ]
        },
        {
          type: 'SPC_PROBABILISTIC',
          name: 'Day 3 Probabalistic',
          layers: [
            {
              id: 'SPC_PROBABILISTIC',
              url: 'https://www.spc.noaa.gov/products/outlook/day3otlk_prob.nolyr.geojson'
            },
            {
              id: 'SPC_PROBABILISTIC_SIG',
              url: 'https://www.spc.noaa.gov/products/outlook/day3otlk_sigprob.nolyr.geojson'
            },
          ]
        },
        {
          type: 'SPC_PROBABILISTIC',
          name: 'Day 4 Probabalistic',
          layers: [
            {
              id: 'SPC_PROBABILISTIC',
              url: 'https://www.spc.noaa.gov/products/exper/day4-8/day4prob.nolyr.geojson'
            },
          ]
        },
        {
          type: 'SPC_PROBABILISTIC',
          name: 'Day 5 Probabalistic',
          layers: [
            {
              id: 'SPC_PROBABILISTIC',
              url: 'https://www.spc.noaa.gov/products/exper/day4-8/day5prob.nolyr.geojson'
            },
          ]
        },
        {
          type: 'SPC_PROBABILISTIC',
          name: 'Day 6 Probabalistic',
          layers: [
            {
              id: 'SPC_PROBABILISTIC',
              url: 'https://www.spc.noaa.gov/products/exper/day4-8/day6prob.nolyr.geojson'
            },
          ]
        },
        {
          type: 'SPC_PROBABILISTIC',
          name: 'Day 7 Probabalistic',
          layers: [
            {
              id: 'SPC_PROBABILISTIC',
              url: 'https://www.spc.noaa.gov/products/exper/day4-8/day7prob.nolyr.geojson'
            },
          ]
        },
        {
          type: 'SPC_PROBABILISTIC',
          name: 'Day 8 Probabalistic',
          layers: [
            {
              id: 'SPC_PROBABILISTIC',
              url: 'https://www.spc.noaa.gov/products/exper/day4-8/day8prob.nolyr.geojson'
            },
          ]
        },
      ],
      selectedSite: null,
      scans: [],
      numOfScansToLoad: 6,

      currentTsInterval: null,
      radarToolkitRadarStationInterval: null,
    }
  },
  async mounted() {
    this.radarToolkit = new RadarToolkit()
    await this.radarToolkit.initializeMap();
    await this.radarToolkit.initializeCache();
    await this.radarToolkit.loadRadarStations();
    await this.radarToolkit.addLightning();

    document.addEventListener('keydown', (evt) => {
      if (this.radarToolkit.loadedScans.length > 1) {
        if (evt.keyCode === 37) {
          this.radarToolkit.previousFrame()
        }
        if (evt.keyCode === 39) {
          this.radarToolkit.nextFrame()
        }
      }
    });

    if (this.currentTsInterval !== null) clearInterval(this.currentTsInterval)
    this.currentTsInterval = setInterval(() => {
      this.currentTs = moment.utc().subtract(20, 'minutes').unix()
    }, 1000)

    if (this.radarToolkitRadarStationInterval !== null) clearInterval(this.currentTsInterval)
    this.radarToolkitRadarStationInterval = setInterval(() => {
      this.radarToolkit.loadRadarStations()
    }, 5 * 1000 * 60)
  },
  unmounted() {
    console.log('clearing intervals')
    clearInterval(this.currentTsInterval)
    clearInterval(this.radarToolkitRadarStationInterval)
    this.currentTsInterval = null;
    this.radarToolkitRadarStationInterval = null;
  },
  watch: {
    numOfScansToLoad: function (value) {
      this.radarToolkit.updateScansToLoad(value);
    }
  },
  methods: {
    async selectedProduct(product) {
      await this.removeLayers(this.activeSpcLayers);

      for (const layer of product.layers) {
        console.log(layer)
        this.map.addSource(layer.id, {
          type: 'geojson',
          data: layer.url
        })

        let paintParams = {
          'fill-outline-color': ['get', 'stroke'],
        }

        if (!layer.id.includes('SIG')) {
          paintParams['fill-color'] = ['get', 'fill'];
          paintParams['fill-opacity'] = 0.7
        }

        if (layer.id.includes('SIG')) {
          paintParams['fill-pattern'] = 'hatch-line-2'
        }

        this.map.addLayer({
          'id': layer.id,
          'type': 'fill',
          'source': layer.id,
          'paint': paintParams
        }, 'water');

        this.activeSpcLayers.push(layer.id)
      }
    },
    async removeLayers(layers) {
      console.log('removing layers ', layers)
      if (layers.length > 0) {
        for (const layer of layers) {
          console.log('removing ', layer)
          try {
            if (this.map.getLayer(layer)) this.map.removeLayer(layer)
          } catch {
            console.log('Error removing layer: ' + layer)
          }
          if (this.map.getSource(layer)) {
            console.log('source exists for ', layer)
          }
          try {
            if (this.map.getSource(layer)) this.map.removeSource(layer)
          } catch {
            console.log('Error removing source: ' + layer)
          }
          if (this.map.getSource(layer)) console.log('source still exists')
        }

        layers = []

      }

      console.log('done removing layers, layers left', layers)
    },
    screenShot() {
      let filename = "wxvis_screenshot_" + moment.utc().local().format("YYYY_MM_DD_HH_mm_ss") + ".png";
      let mapCanvas = this.map.getCanvas()

      /// create an "off-screen" anchor tag
      var lnk = document.createElement('a');

      /// the key here is to set the download attribute of the a tag
      lnk.download = filename;

      /// convert canvas content to data-uri for link. When download
      /// attribute is set the content pointed to by link will be
      /// pushed as "download" in HTML5 capable browsers
      lnk.href = mapCanvas.toDataURL("image/png;base64");

      // Emulate click to download
      lnk.click();

    },
    async loadTidesAndCurrentsStations() {
      let res = await axios.get("https://api.tidesandcurrents.noaa.gov/mdapi/prod/webapi/stations.json")
      let resData = res.data

      let geoJson = {
        type: "FeatureCollection",
        features: [],
      }

      for (let station of resData.stations) {
        geoJson.features.push({
          geometry: {
            type: 'Point',
            coordinates: [station.lng, station.lat]
          },
          properties: station,
          type: 'Feature'
        });
      }
      console.log(geoJson)

      this.map.addSource('tidesandcurrents-stations', {
        type: 'geojson',
        data: geoJson
      })

      this.map.addLayer({
        'id': 'tidesandcurrents-stations',
        'type': 'symbol',
        'source': 'tidesandcurrents-stations',
        'layout': {
          'icon-image': 'WaterTideStation',
          'icon-size': 0.25
        },
        'paint': {
          'text-color': "#fff",
          'icon-halo-width': 20,
          'icon-halo-color': "#fff"
        }
      });

    },
    async loadBuoyStations() {
      let res = await axios.get("https://www.ndbc.noaa.gov/ndbcmapstations.json")
      let resData = res.data

      let geoJson = {
        type: "FeatureCollection",
        features: [],
      }

      for (let station of resData.station) {
        geoJson.features.push({
          geometry: {
            type: 'Point',
            coordinates: [station.lon, station.lat]
          },
          properties: station,
          type: 'Feature'
        });
      }
      console.log(geoJson)

      this.map.addSource('buoy', {
        type: 'geojson',
        data: geoJson
      })

      this.map.addLayer({
        'id': 'buoy',
        'type': 'symbol',
        'source': 'buoy',
        'layout': {
          'icon-image': 'BuoyRecent',
          'icon-size': 0.25
        },
        'paint': {
          'text-color': "#fff",
          'icon-halo-width': 20,
          'icon-halo-color': "#fff"
        }
      });
    },
    getActiveScanTimestamp() {
      if (this.radarToolkit !== null && this.radarToolkit.animation.activeTimestamp !== null) {
        var timestamp = this.radarToolkit.getActiveScanTimestamp();
        return moment.utc(timestamp).local().format("h:mm A")
      }
      return "Not selected";
    }
  }
}
</script>

<style>
html,
body,
#app {
  margin: 0px;
  width: 100%;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
}
</style>
