
const path = require('path');

require('./30')
require('./56')
require('./58')
require('./59')
require('./61')
require('./62')
require('./78')
require('./80')
require('./94')
require('./99')
require('./134')
require('./141')
require('./153')
require('./154')
require('./159')
require('./161')
require('./165')
require('./170')
require('./172')
require('./177')
require('./180')
require('./182')
require('./186')

// load all products in folder automatically
const folders = ["134","141","153","154","159","161","165","170","172","177","180","182","186","30","56","58","59","61","62","78","80","94","99","index.js"].filter((folder) => folder !== 'index.js');
// eslint-disable-next-line import/no-dynamic-require, global-require
const productsRaw = folders.map((folder) => require('./' + folder));

// make up a list of products by integer type
const products = {};
productsRaw.forEach((product) => {
	if (products[product.code]) { throw new Error(`Duplicate product code ${product.code}`); }
	products[product.code] = product;
});

// list of available product code abbreviations for type-checking
const productAbbreviations = productsRaw.map((product) => product.abbreviation).flat();

module.exports = {
	products,
	productAbbreviations,
};
