import { Buffer } from 'buffer';

function mc(coords) {
    function mercatorXfromLng(lng) {
        return (180 + lng) / 360;
    }
    function mercatorYfromLat(lat) {
        return (180 - (180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 360)))) / 360;
    }
    return [mercatorXfromLng(coords[0]), mercatorYfromLat(coords[1])];
}


function xmlToJson(xml) {
    if (typeof xml == "string") {
        var parser = new DOMParser();
        xml = parser.parseFromString(xml, "text/xml");
    }
    // Create the return object
    var obj = {};
    // console.log(xml.nodeType, xml.nodeName );
    if (xml.nodeType == 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj["@attributes"] = {};
            for (var j = 0; j < xml.attributes.length; j++) {
                var attribute = xml.attributes.item(j);
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
        }
    }
    else if (xml.nodeType == 3 ||
        xml.nodeType == 4) { // text and cdata section
        obj = xml.nodeValue
    }
    // do children
    if (xml.hasChildNodes()) {
        for (var i = 0; i < xml.childNodes.length; i++) {
            var item = xml.childNodes.item(i);
            var nodeName = item.nodeName;
            if (typeof (obj[nodeName]) == "undefined") {
                obj[nodeName] = xmlToJson(item);
            } else {
                if (typeof (obj[nodeName].length) == "undefined") {
                    var old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                if (typeof (obj[nodeName]) === 'object') {
                    obj[nodeName].push(xmlToJson(item));
                }
            }
        }
    }
    return obj;
}

function scansFromBucketJsonResponse(bucketResponseData) {
    let entries = [];
    if (bucketResponseData) {
        try {
            for (var entry of bucketResponseData.ListBucketResult.Contents) {
                entries.push({
                    key: entry.Key['#text'],
                    dateTime: entry.LastModified['#text']
                })
            }
        } catch (e) {
            console.log('DEBUG>> Bucket Entries Parse JSON Error', e)
        }
    }
    return entries;
}

function loadFileObject(url, level) {

    var radLevel;
    var wholeOrPart = 'whole';
    if (level == 2) {
        radLevel = 2;
    } if (level == 22) {
        radLevel = 2;
        wholeOrPart = 'part';
    } else if (level == 3) {
        radLevel = 3;
    }
    console.log('Fetch initialized - data requested');
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function () {
        console.log('File finished downloading');
        var response = xhr.response;
        console.log(response)
        var blob;

        if (level != 2) {
            /*
            this block of code is an attempt to catch a level 3 file where the data doesn't
            start until 11 bytes out. This will detect if the first four bytes are "SDUS"
            (something like SDUS32) and if they are not, remove the first 11 bytes
            (the first 11 bytes are the bytes that should be removed to allow the parser to work)
            */
            // store the first eleven bytes for checking
            var fileEarlyBytes = blobToString(response.slice(0, 11));
            // if the first four bytes are not "SDUS"
            if (fileEarlyBytes.slice(0, 4) != "SDUS") {
                // remove those pesky 11 bytes!
                blob = response.slice(11);
            } else {
                // the file is fine, proceed as normal
                blob = response;
            }
        } else {
            blob = response;
        }

        blob.lastModifiedDate = new Date();
        blob.name = url;
        // Create the event
        // var event = new CustomEvent("loadFile", {
        //     "detail": [
        //         blob,
        //         radLevel,
        //         wholeOrPart
        //     ]
        // });
        // // Dispatch/Trigger/Fire the event
        // document.dispatchEvent(event);
    });
    xhr.onprogress = (event) => {
        console.log(event.loaded + " / " + event.total)
        // event.loaded returns how many bytes are downloaded
        // event.total returns the total number of bytes
        // event.total is only available if server sends `Content-Length` header
        //console.log(`%c Downloaded ${ut.formatBytes(event.loaded)} of ${ut.formatBytes(event.total)}`, 'color: #bada55');
        //var complete = (event.loaded / event.total * 50 | 0);
        // console.log(`${ut.formatBytes(event.loaded)}`);
        //ut.progressBarVal('label', ut.formatBytes(event.loaded));
    }
    xhr.send();
}

function blobToString(b) {
    var u, x;
    u = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open('GET', u, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(u);
    return x.responseText;
}

function toBuffer(ab) {
    const buf = Buffer.alloc(ab.byteLength);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
        buf[i] = view[i];
    }
    return buf;
}

function scale(number, inMin, inMax, outMin, outMax) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

function scaleValues(values, product) {
    if (product == 'N0G' || product == 'N0U' || product == 'TVX' || product == 'VEL') {
        // velocity - convert from knots (what is provided in the colortable) to m/s (what the radial gates are in)
        for (var i in values) { values[i] = values[i] / 1.944 }
    } else if (product == 'N0S') {
        // storm relative velocity
        for (var i in values) { values[i] = values[i] - 0.5 }
    } else if (product == 'N0H' || product == 'HHC') {
        // hydrometer classification || hybrid hydrometer classification
        for (var i in values) { values[i] = values[i] + 0.5 }
    }
    return values;
}

function npDiff(arr) {
    var returnArr = [];
    for (var i in arr) {
        i = parseInt(i);
        var theDiff = arr[i + 1] - arr[i];
        if (!Number.isNaN(theDiff)) {
            // if (theDiff > 180) { theDiff -= 360 }
            // if (theDiff < -180) { theDiff += 360 }
            returnArr.push(theDiff);
        }
    }
    return returnArr;
}

const mean = array => array.reduce((a, b) => a + b) / array.length;

function removeLast(arr) {
    arrCopy = [...arr];
    arrCopy.pop();
    return arrCopy;
}

function removeFirst(arr) {
    arrCopy = [...arr];
    arrCopy.shift();
    return arrCopy;
}

// https://stackoverflow.com/a/40475362/18758797
function npLinspace(startValue, stopValue, cardinality) {
    var arr = [];
    var step = (stopValue - startValue) / (cardinality - 1);
    for (var i = 0; i < cardinality; i++) {
        arr.push(startValue + (step * i));
    }
    return arr;
}

function deg2rad(angle) { return angle * (Math.PI / 180) }

function getXlocs(ref_range, az) {
    var xlocs = [];
    for (var i in az) {
        var newRow = [];
        for (var n in ref_range) {
            newRow.push(ref_range[n] * Math.sin(deg2rad(az[i])));
        }
        xlocs.push(newRow);
    }
    return xlocs;
}
function getYlocs(ref_range, az) {
    var ylocs = [];
    for (var i in az) {
        var newRow = [];
        for (var n in ref_range) {
            newRow.push(ref_range[n] * Math.cos(deg2rad(az[i])));
        }
        ylocs.push(newRow);
    }
    return ylocs;
}

function getRadarLatLng(radarObj, level) {
    function hasWhiteSpace(s) {
        return s.indexOf(' ') >= 0;
    }
    if (level == 2) {
        if (radarObj?.data[1][0]?.record?.volume?.hasOwnProperty('latitude')) {
            return {
                'lat': radarObj.data[1][0].record.volume.latitude,
                'lng': radarObj.data[1][0].record.volume.longitude
            }
        } else if (!hasWhiteSpace(radarObj.header.ICAO)) {
            var icao = radarObj.header.ICAO;
            return {
                'lat': -81.86,
                'lng': 41.41305
            }
        }
    } else if (level == 3) {
        if (radarObj?.productDescription?.hasOwnProperty('latitude')) {
            return {
                'lat': radarObj.productDescription.latitude,
                'lng': radarObj.productDescription.longitude
            }
        } else if (radarObj?.textHeader?.hasOwnProperty('id3')) {
            // var icao = stationAbbreviations[l3rad.textHeader.id3];
            return {
                'lat': -81.86,
                'lng': 41.41305
            }
        }
    }
}



export {
    mc, xmlToJson, loadFileObject, toBuffer, scale, scaleValues, scansFromBucketJsonResponse, npDiff, mean, removeLast, removeFirst, npLinspace,
    deg2rad, getXlocs, getYlocs, getRadarLatLng
}
